import React from "react"
import { Container, Row, Col } from "@ui/wrapper"
import Anchor from "@ui/anchor"
import Heading from "@ui/heading"
import Text from "@ui/text"
import {
  ContactoInfoWrapper,
  InfoItem,
  InfoTitle,
  IFrameWrapper,
} from "./contacto-info-area.style"

const ContactoInfoArea = data => {
  const { address, phone, phone2, phone3, email, email2 } = data.data.contact
  return (
    <ContactoInfoWrapper>
      <Container>
        <Row>
          <Col md={6} align="center">
            <IFrameWrapper>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902.5246082168724!2d-77.08747278350678!3d-12.007361291493574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105ce8e78bc679d%3A0x8bcea8fb59a2946a!2sAv.%20Ang%C3%A9lica%20Gamarra%202180%2C%20Cercado%20de%20Lima%2015108!5e0!3m2!1ses-419!2spe!4v1635905717765!5m2!1ses-419!2spe"
                width="450"
                height="450"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </IFrameWrapper>
          </Col>
          <Col md={6}>
            <Heading>Contacta con nosotros</Heading>
            <Text>
              Puedes llamarnos directamente o contactar con un miembro de
              nuestro equipo.
            </Text>
            <InfoItem>
              <i class="fas fa-phone-volume"></i>
              <InfoTitle>Teléfono</InfoTitle>
              <p>
                <Anchor path={`tel:${phone}`}>{phone}</Anchor>
              </p>
              <p>
                <Anchor path={`tel:${phone2}`}>{phone2}</Anchor>
              </p>
              <p>
                <Anchor path={`tel:${phone3}`}>{phone3}</Anchor>
              </p>
            </InfoItem>
            <InfoItem>
              <i class="fal fa-map-marker-alt"></i>
              <InfoTitle>Dirección</InfoTitle>
              <p>{address}</p>
            </InfoItem>
            <InfoItem>
              <i class="far fa-envelope"></i>
              <InfoTitle>Correos</InfoTitle>
              <p>
                <Anchor path={`mailto:${email}`}>{email}</Anchor>
              </p>
              <p>
                <Anchor path={`mailto:${email2}`}>{email2}</Anchor>
              </p>
            </InfoItem>
          </Col>
        </Row>
      </Container>
    </ContactoInfoWrapper>
  )
}

export default ContactoInfoArea
