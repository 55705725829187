import styled, { themeGet } from "@styled"

export const ContactoInfoWrapper = styled.div`
  background-color: #fff;
  padding: 60px 0;
`
export const InfoItem = styled.div`
  margin-bottom: 30px;
  p {
    line-height: 1.5;
    margin-left: 30px;
  }
  i {
    font-size: 24px;
    color: ${themeGet("colors.background")};
    min-width: 30px;
  }
`

export const InfoTitle = styled.div`
  display: inline-block;
  color: #000;
  margin-bottom: 4px;
`
export const IFrameWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  > *{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
`
