import styled, { themeGet } from "@styled"

export const EspecialidadWrap = styled.div`
  text-align: center;
  line-height: 1.5em;
  margin: 40px 0;
`

export const EspecialidadFoto = styled.div``

export const EspecialidadInfo = styled.div``

export const EspecialidadNombre = styled.h5`
  color: ${themeGet("colors.background")};
`
