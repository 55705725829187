import React from "react"
import Image from "@ui/image"
import {
  EspecialistaWrap,
  EspecialistaInfo,
  EspecialistaNombre,
  EspecialistaFoto,
} from "./style"

const Especialista = ({ especialista }) => {
  return (
    <EspecialistaWrap>
      <EspecialistaFoto>
        <Image src={especialista.foto} alt={especialista.nombre.text} />
      </EspecialistaFoto>
      <EspecialistaInfo>
        <EspecialistaNombre>{especialista.nombre.text}</EspecialistaNombre>
        <p>{especialista.titulo.text}</p>
        <p>COP: {especialista.cop.text}</p>
      </EspecialistaInfo>
    </EspecialistaWrap>
  )
}

export default Especialista
