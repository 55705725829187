import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Fade } from "react-awesome-reveal"
import Especialidad from "@components/especialidad/layout-01"
import Heading from "@ui/heading"
import Text from "@ui/text"
import { Container, Row, Col } from "@ui/wrapper"
import { EspecialidadesDestacadasAreaWrapper } from "./especialidades-destacadas-area.style"

const EspecialidadesDestacadasArea = () => {
  const especialidadesData = useStaticQuery(graphql`
    {
      especialidad1: prismicEspecialidad(uid: { eq: "ortodoncia" }) {
        data {
          nombre {
            text
          }
          foto {
            gatsbyImageData
          }
          descripcion_corta {
            text
          }
        }
      }
      especialidad2: prismicEspecialidad(uid: { eq: "implante-dental" }) {
        data {
          nombre {
            text
          }
          foto {
            gatsbyImageData
          }
          descripcion_corta {
            text
          }
        }
      }
      especialidad3: prismicEspecialidad(uid: { eq: "rehabilitacion-oral" }) {
        data {
          nombre {
            text
          }
          foto {
            gatsbyImageData
          }
          descripcion_corta {
            text
          }
        }
      }
      especialidad4: prismicEspecialidad(uid: { eq: "estetica-dental" }) {
        data {
          nombre {
            text
          }
          foto {
            gatsbyImageData
          }
          descripcion_corta {
            text
          }
        }
      }
    }
  `)
  return (
    <EspecialidadesDestacadasAreaWrapper>
      <Container>
        <Row>
          <Col md={6}>
            <Heading>Tu sonrisa en las mejores manos</Heading>
            <Text>
              En Prodental traemos la última generación en tratamientos para que
              consigas la sonrisa que siempre has querido.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <Fade left>
              <Especialidad
                especialidad={especialidadesData.especialidad1.data}
              />
            </Fade>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Fade left>
              <Especialidad
                especialidad={especialidadesData.especialidad2.data}
              />
            </Fade>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Fade right>
              <Especialidad
                especialidad={especialidadesData.especialidad3.data}
              />{" "}
            </Fade>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Fade right>
              <Especialidad
                especialidad={especialidadesData.especialidad4.data}
              />{" "}
            </Fade>
          </Col>
        </Row>
      </Container>
    </EspecialidadesDestacadasAreaWrapper>
  )
}

export default EspecialidadesDestacadasArea
