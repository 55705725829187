import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "@ui/wrapper"
import Heading from "@ui/heading"
import Especialidad from "@components/especialidad/layout-02"
import { EspecialidadWrapper } from "./especialidades-area.style"

const EspecialidadesArea = () => {
  const especialidadesData = useStaticQuery(graphql`
    {
      allPrismicEspecialidad(
        sort: { fields: data___numero_de_orden, order: ASC }
      ) {
        edges {
          node {
            data {
              nombre {
                text
              }
              icono {
                gatsbyImageData
              }
              descripcion {
                text
              }
            }
          }
        }
      }
    }
  `)
  const especialidades = especialidadesData.allPrismicEspecialidad.edges
  return (
    <EspecialidadWrapper id="especialidades">
      <Container>
        <Row>
          <Col lg={6} mt="auto" mb="auto">
            <Heading as="h3">
              Especialidades para el cuidado de tu familia
            </Heading>
          </Col>
          <Col lg={6}>
            <Row>
              {especialidades.map(({ node: especialidad }) => (
                <Col lg={4} key={especialidad.data.nombre.text}>
                  <Especialidad especialidad={especialidad} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </EspecialidadWrapper>
  )
}

export default EspecialidadesArea
