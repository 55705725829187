import styled from "styled-components"

export const EspecialidadWrap = styled.div`
  text-align: center;
  margin: 60px 0;
`

export const EspecialidadFoto = styled.div`
  margin-bottom: 20px;
`

export const EspecialidadInfo = styled.div`
  p {
    text-align: justify;
    line-height: 1.5;
  }
`

export const EspecialidadNombre = styled.h4``
