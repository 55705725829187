import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "@ui/wrapper"
import Especialista from "@components/especialista/layout-01"
import SectionTitle from "@ui/section-title"
import { EquipoWrapper } from "./equipo-area.style"

const EquipoArea = () => {
  const equipoData = useStaticQuery(graphql`
    {
      allPrismicEspecialista(
        sort: { fields: data___numero_de_orden, order: ASC }
      ) {
        edges {
          node {
            data {
              titulo {
                text
              }
              nombre {
                text
              }
              foto {
                gatsbyImageData
              }
              cop {
                text
              }
            }
          }
        }
      }
    }
  `)
  const especialistas = equipoData.allPrismicEspecialista.edges
  return (
    <EquipoWrapper>
      <Container>
        <SectionTitle
          title="Nuestro equipo de especialistas"
          subtitle="Somos un equipo de especialistas en odontología integral, estética dental y rehabilitación oral con más de veinte años de experiencia"
          layout="2"
        />
        <Row align="center" gutters={{ lg: 60 }}>
          {especialistas &&
            especialistas.map((especialista, i) => (
              <Col md={4} key={`especialista-${i}`} mb="30px">
                <Especialista especialista={especialista.node.data} />
              </Col>
            ))}
        </Row>
      </Container>
    </EquipoWrapper>
  )
}

export default EquipoArea
