import styled, { themeGet, device } from "@styled"

export const HeroWrapper = styled.section`
  background-color: ${themeGet("colors.background")};
  position: relative;
`

export const HeroTextBox = styled.div`
  color: #fff;
  max-width: 605px;
  width: 100%;
  padding-inline-start: 0;
  margin-block-start: 50px;
  margin-block-end: 20px;
  margin-inline: auto;
  text-align: center;

  ${device.medium} {
    padding-inline-start: 30px;
    margin-inline-start: auto;
    margin-inline-end: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: left;
  }
`

export const HeroSeparator = styled.div`
  position: absolute;
  bottom: -1px;
  display: block;
  width: 100%;
  z-index: 1;
  line-height: 0;
  svg {
    fill: #fff;
    height: 100%;
    width: 100%;
  }
  i {
    color: #f8f8f8;
    height: 100px;
    width: 100%;
  }
`

export const ImageBoxWrap = styled.div`
  //position: relative;
  margin-inline: auto;
  max-width: 370px;

  ${device.medium} {
    padding-block-start: 160px;
    max-width: 100%;
    margin-inline: 0;
  }
  ${device.large} {
    margin-block-start: 20px;
    padding-block-start: 0;
  }
`

export const ImageBoxOne = styled.div`
  //position: absolute;
  //bottom: 100px;
  width: 100%;
  max-width: 260px;
  margin-inline: auto;
  ${device.medium} {
    max-width: 100%;
    margin-inline: 0;
  }
`

export const ImageBoxTwo = styled.div`
  position: relative;
  z-index: 1;
`

export const StyledSubtitle = styled.h4`
  font-weight: 700;
  color: ${themeGet("colors.primary")};
  font-size: 38px;
`

export const StyledTitle = styled.h1`
  font-weight: 700;
  color: #fff;
  font-size: 73px;
`
