import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "@ui/wrapper"
import { useStaticQuery, graphql } from "gatsby"
import Text from "@ui/text"
import Button from "@ui/button"
import Image from "@ui/image"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { HeadingType, TextType, ButtonType, ImageType } from "@utils/types"
import {
  HeroWrapper,
  HeroTextBox,
  ImageBoxWrap,
  ImageBoxOne,
  HeroSeparator,
  StyledTitle,
  StyledSubtitle,
} from "./style"

const HeroArea = () => {
  const bannerData = useStaticQuery(graphql`
    {
      allPrismicBanner {
        edges {
          node {
            data {
              titulo {
                text
              }
              texto {
                text
              }
              subtitulo {
                text
              }
              link_2 {
                url
              }
              link_1 {
                url
              }
              imagen {
                gatsbyImageData
              }
              boton_2 {
                text
              }
              boton_1 {
                text
              }
            }
          }
        }
      }
    }
  `)
  const banners = bannerData.allPrismicBanner.edges
  const { titulo, texto, subtitulo, link_1, link_2, imagen, boton_1, boton_2 } =
    banners[0].node.data
  return (
    <HeroWrapper>
      <Container fluid>
        <Row alignItems="center">
          <Col md={6}>
            <HeroTextBox>
              {subtitulo && (
                <StyledSubtitle as="h4">{subtitulo.text}</StyledSubtitle>
              )}
              {titulo && <StyledTitle as="h1">{titulo.text}</StyledTitle>}
              {texto && <Text>{texto.text}</Text>}
              {boton_1 && (
                <AnchorLink offset="200" href="#especialidades">
                  <Button
                    key={link_1.url}
                    mt="15px"
                    shape="ellipse"
                    hoverStyle="two"
                  >
                    {boton_1.text}
                  </Button>
                </AnchorLink>
              )}
              {boton_2 && (
                <AnchorLink offset="200" href="#testimonios">
                  <Button
                    color="white"
                    key={link_2.url}
                    mt="15px"
                    shape="ellipse"
                    ml="62px"
                    hoverStyle="three"
                  >
                    {boton_2.text}
                  </Button>
                </AnchorLink>
              )}
            </HeroTextBox>
          </Col>
          <Col md={6}>
            <ImageBoxWrap>
              {imagen && (
                <ImageBoxOne>
                  <Image src={imagen.gatsbyImageData} alt={titulo.text} />
                </ImageBoxOne>
              )}
            </ImageBoxWrap>
          </Col>
        </Row>
      </Container>
      <HeroSeparator>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1557.41 227.87">
          <path d="M1556.91.66V227.1l-1556,.27S.36,124.17.53,124.26C400.41,334.1,1556.91.66,1556.91.66Z" />
        </svg>
      </HeroSeparator>
    </HeroWrapper>
  )
}

HeroArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
  }),
}

export default HeroArea
