import * as React from "react"
import PropTypes from "prop-types"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/layout-03"
import Footer from "@layout/footer/layout-01"
import HeroArea from "@containers/index/hero/layout-01"
import EspecialidadesDestacadasArea from "@containers/index/especialidades-destacadas-area"
import EquipoArea from "@containers/index/equipo-area"
import Especialidades from "@containers/index/especialidades-area"
import TestimoniosArea from "@containers/index/testimonios-area"
import ContactoArea from "@containers/index/contacto-area/layout-05"
import ContactoInfoArea from "@containers/index/contacto-info-area"
import { headerData, footerData } from "@constants"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Inicio" />
    <Header data={headerData} />

    <main className="site-wrapper-reveal">
      <HeroArea />
      <EspecialidadesDestacadasArea />
      <EquipoArea />
      <Especialidades />
      <TestimoniosArea />
      <ContactoArea />
      <ContactoInfoArea data={headerData} />
    </main>
    <Footer data={footerData} />
  </Layout>
)

IndexPage.propTypes = {
  pageContext: PropTypes.shape({}),
  location: PropTypes.shape({}),
}

export default IndexPage
