import React from "react"
import Image from "@ui/image"
import {
  EspecialidadWrap,
  EspecialidadFoto,
  EspecialidadInfo,
  EspecialidadNombre,
} from "./style"

const Especialidad = ({ especialidad }) => {
  return (
    <EspecialidadWrap>
      <EspecialidadFoto>
        <Image src={especialidad.foto} alt={especialidad.nombre.text} />
      </EspecialidadFoto>
      <EspecialidadInfo>
        <EspecialidadNombre>{especialidad.nombre.text}</EspecialidadNombre>
        <p>{especialidad.descripcion_corta.text}</p>
      </EspecialidadInfo>
    </EspecialidadWrap>
  )
}

export default Especialidad
